/**
* @fileoverview Indexjs Componente principal del chatweb
* @version  2.0Beta
* @author Hugo Chicoj<hugocc@automtadg.com>
* @copyright  Automtadg.com
*
* History
* v2.0Beta – Se agregaron los parametros automataBot, automataEmpres y automtaAvatar
*
* v1.0Beta Fue desarrollada por Dina Antuche <madian@automtadg.com>
*/

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

/**
@param {string} automataBot - El id del bot de automata
@param {string} automataEmpresa - El id de la empresa de automata
@param {string} automataAvatar - La url del avatar que aparecera en el chat
*/
//  <App automataBot={"1"} automataEmpresa={"202001"} automataAvatar={'https://i.imgur.com/4Vd0kJa.png'}/>
const Principal = ({settings}) =>(
  <React.StrictMode>
    <App automataBot={settings.automataBot} automataEmpresa={settings.automataEmpresa} automataAvatar={settings.automataAvatar} automataNameChat={settings.automataNameChat}/>
  </React.StrictMode>
);


const chatweb =document.getElementById('AutomataChatWeb');
if(chatweb){
  const id = chatweb.dataset.id;
  const settings = window.erwSettings[id];
  /*const settings={automataBot:"1",
            automataEmpresa:"202001",
            automataAvatar:'https://i.imgur.com/4Vd0kJa.png',
          }*/
  ReactDOM.render(<Principal settings={settings}/>,chatweb)
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
